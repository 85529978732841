/*COLORS

GREEN: #C1BC00
DARK BLUE: #2D3544
ORANGE: #D06F1A
FONT COLOR: #171C29
TAN: F7F9EA
*/


$primary: #0066a1;


$container-max-widths: (
        sm: 540px,
        md: 720px,
        lg: 960px,
        xl: 1400px
)