
.button {
  @extend .btn;
  @extend .btn-secondary;

  &:visited, &:active {
    color: white;
  }
}

.button.green {
  @extend .btn-primary;

}
.button.red {
  //background-color: orange;
  //border-color: orange;
}
.button.blue { }


a.btn-primary {
  color: white !important;
  text-decoration: none !important;
}

.buttonHolder {

  h1,h2 { flex: 1; }
  display: flex;
  justify-content: flex-end;
  margin-bottom: 30px;

  input {
    margin-left: 10px;
  }
}