

.buttonHolder {
    .button { 
        min-width: 140px;
    }
}


body.multi_content {
    padding-bottom: 0;
    .footer-wrapper {
        display: none;
    }

    #wrapper {
        padding-bottom: 0;
    }


    #columns, #right, .page-editor-wrap, form#form1 {
        display: flex;
        flex-direction: column;
        height: 100%;
    }

    #iframeHeight-Container-0,.page-editor-wrap {
        flex: 1;
    }

    iframe#page_preview {
        height: 100%;
        border: none;
        width: 100%;
        position: relative;
    }





    .content-actions-wrapper {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        margin: 20px 0;
    }
    .page-header-wrapper {
        background-color: #efefef;
        padding: 11px 0;
        box-shadow: 0 5px 8px rgba(0, 0, 0, .07);
        z-index: 100;
        border-bottom: 1px solid #dedede;
    }

    .page-header {
        display: flex;
    }

    .page-details-wrapper {
        flex: 1;
    }

    div#iframeHeight-Container-0 {
        margin-top: 21px !important;
    }


    .content-actions-wrapper .btn-holder {

    }
    .popup {
        display: none;
        /*position: fixed;*/
        position:fixed;
        border: 1px solid #aaa;
        background-color: white;
        min-height: 275px;
        -moz-box-shadow: 0 3px 9px rgba(0, 0, 0, .5);
        -webkit-box-shadow: 0 3px 9px rgba(0, 0, 0, .5);
        box-shadow: 0 3px 9px rgba(0, 0, 0, .5);
        border-radius: 8px;
        -moz-border-radius: 8px;
        padding: 11px;
        z-index: 5000;
        top: 25%;
        left: 50%;
        margin-left: -40%;
        width: 80%;
        height: 50vh;
        min-width: 500px;
        max-width: 1400px;
        box-sizing: border-box;
        -moz-box-sizing: border-box;
    }

    #preview_modal {
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        padding: 0 !important;
        margin: 0 !important;
        background-color: white;
    }

    #preview_modal .modal-content {
        border: none;
        position: fixed;
        display: flex;
        flex-direction: column;
        width: 100%;
        pointer-events: auto;
        background-color: #fff;
        background-clip: padding-box;
        border-radius: 0.3rem;
        outline: 0;
        top: 0;
        left: 0;
        height: 100%;
    }
    #preview_modal .modal-body {
        padding: 0;
        background-color: #efefef;
    }
    #preview_modal .modal-header {
        flex: 0 0 62px;
        border: none;
        padding-bottom: 0;
    }
    #preview_modal .modal-header .close {
        color: black;
        margin-right: 10px;
    }


    #preview_modal .modal-header .close {
        padding: .3rem .3rem;
        margin: -9px 5px !important;
        font-size: 49px;
    }

    #preview_iframe {
        border: 1px solid #cecece;
        box-shadow: 0 0 9px rgba(0,0,0,.1);
        position: relative;
        height: 100%;
        width: 100%;
    }

    .preview_iframe_wrapper {
        display: flex;
        justify-content: center;
        height: calc(100% - 10px);
        margin-top: 10px;
    }

    #preview_modal .modal-dialog {
        padding: 0;
        margin: 0;
        height: 100%;
        position: fixed;
        width: 100%;
        top: 0;
        left: 0;
    }

    #preview_modal_body {
        overflow: auto;
    }

    .btn.selected
    {
        color: #333;
        background-color:#007bff;
    }

    .tox-tinymce-aux {
        z-index: 50001 !important;
    }

    .popup.fade {
        -webkit-transition: -webkit-transform .3s ease-out;
        -moz-transition:    -moz-transform .3s ease-out;
        -o-transition:      -o-transform .3s ease-out;
        transition:         transform .3s ease-out;
        -webkit-transform: translate(0, -25%);
        -ms-transform: translate(0, -25%);
        transform: translate(0, -25%);
    }
    .hide { display:none; }
    #tabs #tab-2 { padding: 10px 0; }
    iframe#page_preview {
        border: none;
    }
    #content_parent { height: 100%; display: block; position: relative; margin-bottom: 6px; }
    #content_container { height: 100%; /*position: absolute; */box-sizing: border-box; -moz-box-sizing: border-box; padding-top: 30px; padding-bottom: 3.5%; top: 0;width: 100%; }
    .popup-header { position: absolute; top: 5px; }
    .popup-footer { position: absolute; bottom: 5px; }
    /* If browser height gets small (500px) */
    @media (max-height: 500px){
        .popup { position:absolute; }
        /*#content_parent { height: 250px; }*/
    }


    form > h3 { margin-bottom: 15px; }
    #sub-tools { margin-top: 0px; margin-bottom: 20px; }

    .ui-sortable { list-style: none; }
    .ui-state-default, .ui-state-highlight { float:left; margin-right: 10px; 	padding: 5px 14px; 	border-radius: 7px 7px 0 0; 	min-width: 50px; min-height: 20px; }

    .defaultSkin .mceIframeContainer {   height: 100%; }
    .tab-settings-popup {
        display:none;
        position: absolute;
        top: 400px;
        width: 500px;
        left: 50%;
        margin-left: -250px;
        border: 1px solid;
        background: #FFFFFF;
        box-shadow: 0px 0px 13px rgba(0,0,0,.3);
        overflow: hidden;
        box-sizing: border-box;
        padding:15px;
    }


    .tab-settings-popup .btn {
        font-size: 12px;
        border-radius: 5px;
        border: 1px solid #888;
        overflow: hidden;
        padding: 1px 8px;
        background-color: #dede5e;
        color: #000;
        text-decoration: none;
        text-transform: uppercase;
        cursor: pointer;
        display:inline-block !important;
        zoom:1;
    }

    .tab-settings-popup label {
        display: block;
    }
    .tab-settings-popup input::after, .tab-settings-popup textarea::after {
        content: " ";
        display: block;
        clear: both;
        margin-bottom: 21px;
    }

    .tab-settings-popup input, .tab-settings-popup textarea {
        width: 100%;
        box-sizing: border-box;
    }

    #content_tabs .edit-tab-settings {
        right: 31px;
        top: 5px;
    }

    h3::before {
        display: block;
        content: " ";
        clear: both;
    }

    #tabs div.tab h3 {
        margin-bottom: 10px;
        margin-top: 5px;
    }


    textarea#edit-tab-description {
        height: 65px;
    }
    input#edit-tab-url {
        margin-bottom: 15px;
    }
    .item-settings-popup {
        display: none;
        position: fixed;
        width: 40%;
        border: 1px solid;
        background: #FFFFFF;
        box-shadow: 0 0 13px rgba(0,0,0,.3);
        box-sizing: border-box;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: 1002;
        padding: 1em;
    }

    .item-settings-popup label { display: block; }
    .item-settings-popup input[type="text"]::after, .item-settings-popup textarea::after { content: " "; display: block; clear: both; margin-bottom: 21px; }
    .item-settings-popup input[type="text"], .item-settings-popup textarea { width: 100%; box-sizing: border-box; text-transform: initial; border-radius: 5px; -moz-border-radius: 5px; padding: 9px; }
    .item-settings-popup select { display: block; clear: both; text-transform: initial; }
    .item-settings-popup select#cms_page_id { width: 100%; }



    .add-content-wrapper{ padding: 1em }
    fieldset { border: 1px solid #545b62; padding: 1em; border-radius: 3px; background: #f1f1f1; margin: 0 auto; width: 50%}

    .buttonHolder { float: right; position: absolute; top: 25px; right: 15px }
    .buttonHolder .button { width: 140px; border: 1px solid #545b62; border-radius: 3px; background: #F1EFF1; color: #545b62 }


}